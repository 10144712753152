button {
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: none;
}

.mdi-icon {
  color: #ffffff;
}

.toolbar-btn {
  color: #ffffff;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s all;
}

.toolbar-btn:hover,
.toolbar-btn.selected {
  background-color: rgba(245, 245, 245, 0.15);
}

.toolbar-btn:hover .tooltip {
  display: block;
  opacity: 0;
  animation: show 0.1s forwards;
  animation-delay: 0.5s;
}

.toolbar-btn:disabled {
  cursor: auto;
}

.toolbar-btn:disabled:hover {
  background-color: transparent;
}

.toolbar-btn:disabled .mdi-icon {
  color: rgba(255, 255, 255, 0.5);
}

.tooltip {
  display: none;
  position: absolute;
  right: 3.25rem;
  width: 100px;
  text-align: center;
  padding: 0.3125rem;
  background-color: #4d4d4d;
  color: #ffffff;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.75);
  z-index: 2;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toolbar-btn-with-dropdown-container .tooltip {
  right: 2.75rem;
}

.dropdown-container {
  display: block;
  position: absolute;
  top: 0;
  right: 2.75rem;
  background-color: #4d4d4d;
  width: auto;
  min-width: 10rem;
  max-width: 15rem;
  padding: 0.5rem;
  border-radius: 0.3125rem;
  animation: pop 0.3s forwards;
}

.dropdown-list-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: 0.2s;
}

.dropdown-list-item:hover {
  background-color: rgba(245, 245, 245, 0.15);
}

.list-item-text {
  color: #ffffff;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.toolbar-btn-with-dropdown-container {
  position: relative;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  font-size: 0.75rem;
  color: #4d4d4d;
}

.checkbox-input {
  position: relative;
  left: 0.9375rem;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-input:disabled + .check-icon {
  opacity: 0.5;
  cursor: auto;
}

.check-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 1.13rem;
  width: 1.13rem;
  background-color: transparent;
  transition: 0.2s;
  border-radius: 0.3125rem;
  border: 1px solid #b4b4b4;
  margin-left: -0.4375rem;
  margin-right: 0.5rem;
  color: transparent;
}

.check-icon .mdi-icon {
  width: 1.125rem;
  height: 1.125rem;
  color: transparent;
}

.checkbox-input:checked ~ .check-icon {
  background-color: #2686b9;
  border: 1px solid #2686b9;
}

.checkbox-input:checked ~ .check-icon .mdi-icon {
  color: #ffffff;
}

.size-tools-wrapper {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #6d6d6d;
}
