* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

iframe {
  border: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
