.disable-select {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.toolbar {
  display: none;
}

/* Always show toolbar for IE11 due to technical limitations */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .toolbar {
    display: flex;
    position: absolute;
    top: 50%;
    right: 2rem;
    margin-top: -7.031rem;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 1rem -0.3rem rgba(0, 0, 0, 0.75);
    animation: none !important;
  }
}

.drawio:hover + .toolbar,
.toolbar:hover {
  display: flex;
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -7.031rem;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 1rem -0.3rem rgba(0, 0, 0, 0.75);
  animation: pop 0.3s forwards;
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }
  50% {
    opacity: 1;
    transform: translateX(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0rem);
  }
}

.drawio {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-screen {
  background-color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}

.loading-screen .mdi-icon {
  height: 36px;
  width: 36px;
  color: black;
  animation: spin 2s infinite linear;
}

.loading-text {
  color: #4d4d4d;
  font-size: 18px;
  margin-left: 8px;
}
