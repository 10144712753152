.disable-select {
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.toolbar {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .toolbar {
    background-color: #000000bf;
    border-radius: .5rem;
    flex-direction: column;
    margin-top: -7.031rem;
    padding: .5rem;
    display: flex;
    position: absolute;
    top: 50%;
    right: 2rem;
    box-shadow: 0 .2rem 1rem -.3rem #000000bf;
    animation: none !important;
  }
}

.drawio:hover + .toolbar, .toolbar:hover {
  background-color: #000000b3;
  border-radius: .5rem;
  flex-direction: column;
  margin-top: -7.031rem;
  padding: .5rem;
  animation: .3s forwards pop;
  display: flex;
  position: absolute;
  top: 50%;
  right: 2rem;
  box-shadow: 0 .2rem 1rem -.3rem #000000bf;
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }

  50% {
    opacity: 1;
    transform: translateX(-.5rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.drawio {
  width: 100%;
  height: 100%;
  position: relative;
}

.loading-screen {
  z-index: 9999;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes spin {
  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
}

.loading-screen .mdi-icon {
  color: #000;
  width: 36px;
  height: 36px;
  animation: 2s linear infinite spin;
}

.loading-text {
  color: #4d4d4d;
  margin-left: 8px;
  font-size: 18px;
}

button {
  box-shadow: none;
  background-color: #0000;
  border: none;
  outline: none;
}

.mdi-icon {
  color: #fff;
}

.toolbar-btn {
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: .75rem;
  transition: all .2s;
  display: flex;
}

.toolbar-btn:hover, .toolbar-btn.selected {
  background-color: #f5f5f526;
}

.toolbar-btn:hover .tooltip {
  opacity: 0;
  animation: .1s .5s forwards show;
  display: block;
}

.toolbar-btn:disabled {
  cursor: auto;
}

.toolbar-btn:disabled:hover {
  background-color: #0000;
}

.toolbar-btn:disabled .mdi-icon {
  color: #ffffff80;
}

.tooltip {
  text-align: center;
  color: #fff;
  z-index: 2;
  background-color: #4d4d4d;
  border-radius: .25rem;
  width: 100px;
  padding: .3125rem;
  font-size: .75rem;
  display: none;
  position: absolute;
  right: 3.25rem;
  box-shadow: 0 1px 2px #000000bf;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toolbar-btn-with-dropdown-container .tooltip {
  right: 2.75rem;
}

.dropdown-container {
  background-color: #4d4d4d;
  border-radius: .3125rem;
  width: auto;
  min-width: 10rem;
  max-width: 15rem;
  padding: .5rem;
  animation: .3s forwards pop;
  display: block;
  position: absolute;
  top: 0;
  right: 2.75rem;
}

.dropdown-list-item {
  cursor: pointer;
  border-radius: .3125rem;
  align-items: center;
  padding: .5rem;
  transition: all .2s;
  display: flex;
}

.dropdown-list-item:hover {
  background-color: #f5f5f526;
}

.list-item-text {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: .75rem;
  overflow: hidden;
}

.toolbar-btn-with-dropdown-container {
  position: relative;
}

.checkbox-container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #4d4d4d;
  align-items: center;
  font-size: .75rem;
  transition: all .2s;
  display: flex;
}

.checkbox-input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: relative;
  left: .9375rem;
}

.checkbox-input:disabled + .check-icon {
  opacity: .5;
  cursor: auto;
}

.check-icon {
  color: #0000;
  background-color: #0000;
  border: 1px solid #b4b4b4;
  border-radius: .3125rem;
  justify-content: center;
  align-items: center;
  width: 1.13rem;
  height: 1.13rem;
  margin-left: -.4375rem;
  margin-right: .5rem;
  transition: all .2s;
  display: flex;
  position: relative;
}

.check-icon .mdi-icon {
  color: #0000;
  width: 1.125rem;
  height: 1.125rem;
}

.checkbox-input:checked ~ .check-icon {
  background-color: #2686b9;
  border: 1px solid #2686b9;
}

.checkbox-input:checked ~ .check-icon .mdi-icon {
  color: #fff;
}

.size-tools-wrapper {
  border-bottom: 1px solid #6d6d6d;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: Lato, sans-serif;
}

iframe {
  background-color: #fff;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.f5fce44e.css.map */
